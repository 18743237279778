import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { GET_ALL_TASKS } from "../graphql/queries";
import { DELETE_TASK, UPDATE_TASK } from "../graphql/mutations";
import Spinner from "./Spinner";
import TaskList from "./TaskList";
import {Page, PageSection} from "@patternfly/react-core";
import Header from "../layout/Header";
import Sidebar from "../layout/Sidebar";
import {Link} from "react-router-dom";

const TaskListLoader = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);

    const onSidebarToggle = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const { loading, data, refetch } = useQuery(GET_ALL_TASKS);
    const tasks = data?.getAllTasks || [];

    const [deleteTask] = useMutation(DELETE_TASK, { onCompleted: () => refetch() });
    const [updateTask] = useMutation(UPDATE_TASK, { onCompleted: () => refetch() });

    const handleDeleteTask = (id) => deleteTask({ variables: { id } });
    const handleUpdateTask = (id, taskDetails) => updateTask({ variables: { id, input: taskDetails } });

    return (<>
        <Page header={<Header isSidebarOpen={isSidebarOpen} onSidebarToggle={onSidebarToggle} />} sidebar={<Sidebar isSidebarOpen={isSidebarOpen} />}>
            <PageSection>
                <Link to="/tasks">Add</Link>
                {loading ? <Spinner/> : <TaskList tasks={tasks} onDeleteTask={handleDeleteTask} onUpdateTask={handleUpdateTask}/>}
            </PageSection>
        </Page>
    </>)
};

export default TaskListLoader;
