import React, {useState} from 'react';
import { PageSidebar, PageSidebarBody } from '@patternfly/react-core';
import {Nav, NavItem, NavList} from "@patternfly/react-core";
import {Link} from "react-router-dom";

const Sidebar = ({ isSidebarOpen }) => {
    const [activeItem, setActiveItem] = useState(1);

    const onNavSelect = (_event, result) => {
        setActiveItem(result.itemId);
    };
    return (
        <PageSidebar isSidebarOpen={isSidebarOpen} id="vertical-sidebar">
            <PageSidebarBody>
                <Nav onSelect={onNavSelect} aria-label="Nav">
                    <NavList>
                        <NavItem itemId={0} isActive={activeItem === 0}>
                            <Link to="/" onClick={() => setActiveItem(0)} style={{ textDecoration: 'none', color: 'inherit' }}>
                                Task List
                            </Link>
                        </NavItem>
                    </NavList>
                </Nav>
            </PageSidebarBody>
        </PageSidebar>
    );
};

export default Sidebar;
