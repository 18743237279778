import React from "react";
import {
    Form,
    FormGroup,
    TextInput,
    Checkbox,
    Button,
    FormSelectOption, FormSelect
} from "@patternfly/react-core";
import aryIanaTimeZones from "./timezoneList";

const TaskForm = ({task, setTask, onAddTask}) => {

    const handleChange = (e) => {
        setTask({...task, [e.target.name]: e.target.value});
    };

    const handleCheckboxChange = (e) => {
        setTask({...task, [e.target.name]: e.target.checked});
    }

    return (
        <Form>
            <FormGroup label="Name" fieldId="name">
                <TextInput
                    type="text"
                    id="name"
                    name="name"
                    value={task.name}
                    onChange={handleChange}
                />
            </FormGroup>
            <FormGroup label="Due" fieldId="due">
                <TextInput
                    type="datetime-local"
                    id="due"
                    name="due"
                    value={task.due}
                    onChange={handleChange}
                />
            </FormGroup>
            <FormGroup label="Time Zone" fieldId="timeZone">
                <FormSelect
                    id="timeZone"
                    name="timeZone"
                    value={task.timeZone}
                    onChange={handleChange}
                >
                    {aryIanaTimeZones.map((tz, index) => (
                        <FormSelectOption key={index} value={tz} label={tz} />
                    ))}
                </FormSelect>
            </FormGroup>
            <FormGroup label="Estimated Duration" fieldId="estimatedDuration">
                <TextInput
                    type="text"
                    id="estimatedDuration"
                    name="estimatedDuration"
                    value={task.estimatedDuration}
                    onChange={handleChange}
                />
            </FormGroup>
            <FormGroup label="Completed" fieldId="completed">
                <Checkbox
                    id="completed"
                    name="completed"
                    isChecked={task.completed}
                    onChange={handleCheckboxChange}
                />
            </FormGroup>
            <Button variant="primary" onClick={() => onAddTask(task)}>
                Save
            </Button>
        </Form>
    );
};

export default TaskForm;
