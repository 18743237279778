import React from 'react';
import {useTheme} from "./ThemeContext";
import {ToggleGroup, ToggleGroupItem} from "@patternfly/react-core";

import MoonIcon from '@patternfly/react-icons/dist/esm/icons/moon-icon';
import SunIcon from '@patternfly/react-icons/dist/esm/icons/sun-icon';

const ThemeToggleButton = () => {
    const {theme, toggleTheme} = useTheme();


    const toggleDarkTheme = (_evt, selected) => {
        toggleTheme();
    };

    return (
        <ToggleGroup aria-label="Dark theme toggle group">
            <ToggleGroupItem
                aria-label="light theme toggle"
                icon={<SunIcon/>}
                isSelected={theme === 'light'}
                onChange={toggleDarkTheme}
            />
            <ToggleGroupItem
                aria-label="dark theme toggle"
                icon={<MoonIcon/>}
                isSelected={theme === 'dark'}
                onChange={toggleDarkTheme}
            />
        </ToggleGroup>
    );
};

export default ThemeToggleButton;
