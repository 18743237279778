import React from 'react';
import './App.css';
import TaskListLoader from "./components/TaskListLoader";

function App() {
    return (
        <TaskListLoader/>
    )
}

export default App;
