import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import {GET_ALL_TASKS, GET_TASK} from "../graphql/queries";
import { CREATE_TASK, UPDATE_TASK } from "../graphql/mutations";
import Spinner from "./Spinner";
import TaskForm from "./TaskForm";
import {Alert, Page, PageSection} from "@patternfly/react-core";
import Header from "../layout/Header";
import Sidebar from "../layout/Sidebar";

const TaskFormLoader = () => {
    const { taskId } = useParams();
    const navigate = useNavigate();
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [task, setTask] = useState({
        name: null,
        completed: false,
        due: null,
        estimatedDuration: null,
        timeZone: null,
    });
    const [error, setError] = useState(null);

    const idAsInt = taskId ? parseInt(taskId, 10) : null;

    const { loading } = useQuery(GET_TASK, {
        variables: { id: idAsInt },
        skip: !idAsInt,
        onCompleted: (data) => {
            setTask(data?.getTask || {});
        },
    });

    const [createTask] = useMutation(CREATE_TASK, {
        onCompleted: () => navigate("/"),
        onError: (err) => setError(err.message),
        refetchQueries: [{ query: GET_ALL_TASKS }],
    });

    const [updateTask] = useMutation(UPDATE_TASK, {
        onCompleted: () => navigate("/"),
        onError: (err) => setError(err.message),
        refetchQueries: [{ query: GET_ALL_TASKS }],
    });

    const onSidebarToggle = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleAddTask = (taskDetails) => {
        const input = {
            name: taskDetails.name,
            completed: taskDetails.completed,
            due: taskDetails.due,
            estimatedDuration: taskDetails.estimatedDuration,
            timeZone: taskDetails.timeZone,
        };

        if (taskId) {
            updateTask({ variables: { id: idAsInt, input } });
        } else {
            createTask({ variables: { input } });
        }
    };

    return (
        <Page
            header={<Header isSidebarOpen={isSidebarOpen} onSidebarToggle={onSidebarToggle} />}
            sidebar={<Sidebar isSidebarOpen={isSidebarOpen} />}
        >
            <PageSection>
                {error && (
                    <Alert
                        variant="danger"
                        title="An error occurred"
                        actionClose={<button onClick={() => setError(null)}>Close</button>}
                    >
                        {error}
                    </Alert>
                )}
                {loading ? (
                    <Spinner />
                ) : (
                    <TaskForm task={task} setTask={setTask} onAddTask={handleAddTask} />
                )}
            </PageSection>
        </Page>
    );
};

export default TaskFormLoader;
