import React from "react";
import {DataListItem, DataListItemRow, DataListItemCells, DataListCell, Button} from "@patternfly/react-core";
import {Link} from "react-router-dom";

const TaskListItem = ({task, onDeleteTask, onToggleTaskCompletion}) => {
    return (
        <DataListItem aria-labelledby={`task-${task.id}`}>
            <DataListItemRow>
                <DataListItemCells
                    dataListCells={[
                        <DataListCell key="task-name">
              <span
                  onClick={() => onToggleTaskCompletion(task.id)}
                  className={task.completed ? 'completed' : ''}
              >
                {task.name}
              </span>
                        </DataListCell>,
                        <DataListCell key="task-due">{task.due}</DataListCell>,
                        <DataListCell key="task-timezone">{task.timeZone}</DataListCell>,
                        <DataListCell key="task-duration">{task.estimatedDuration}</DataListCell>,
                    ]}
                />
                <DataListCell alignRight isFilled={false}>
                    <Button variant="link" onClick={() => onDeleteTask(task.id)}>Delete</Button>
                    <Link to={`tasks/${task.id}`}>Edit</Link>
                </DataListCell>
            </DataListItemRow>
        </DataListItem>
    );
};

export default TaskListItem;
