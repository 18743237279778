import React from 'react';
import {
    Masthead,
    MastheadToggle,
    MastheadMain,
    MastheadBrand,
    MastheadContent,
    Toolbar,
    ToolbarContent,
    ToolbarGroup,
    PageToggleButton,
    ToolbarItem
} from '@patternfly/react-core';
import {BarsIcon} from '@patternfly/react-icons';
import ThemeToggleButton from "../components/ThemeToggleButton";

const Header = ({isSidebarOpen, onSidebarToggle}) => {
    const headerToolbar = (
        <Toolbar id="vertical-toolbar" isFullHeight isStatic>
            <ToolbarContent>
                <ToolbarGroup
                    variant="icon-button-group"
                    align={{default: 'alignRight'}}
                    spacer={{default: 'spacerNone', md: 'spacerMd'}}
                >
                    <ToolbarItem>
                        <ThemeToggleButton/>
                    </ToolbarItem>
                </ToolbarGroup>
            </ToolbarContent>
        </Toolbar>
    );

    return (
        <Masthead>
            <MastheadToggle>
                <PageToggleButton
                    variant="plain"
                    aria-label="Global navigation"
                    isSidebarOpen={isSidebarOpen}
                    onSidebarToggle={onSidebarToggle}
                    id="vertical-nav-toggle"
                >
                    <BarsIcon/>
                </PageToggleButton>
            </MastheadToggle>
            <MastheadMain>
                <MastheadBrand href="/">
                    Planner
                </MastheadBrand>
            </MastheadMain>
            <MastheadContent>{headerToolbar}</MastheadContent>
        </Masthead>
    );
};

export default Header;
