import { gql } from "@apollo/client";

export const CREATE_TASK = gql`
  mutation CreateTask($input: TaskInputInput!) {
    createTask(input: $input) {
      id
      name
      completed
      due
      estimatedDuration
      timeZone
    }
  }
`;

export const UPDATE_TASK = gql`
  mutation UpdateTask($id: BigInteger!, $input: TaskInputInput!) {
    updateTask(id: $id, input: $input) {
      id
      name
      completed
      due
      estimatedDuration
      timeZone
    }
  }
`;

export const DELETE_TASK = gql`
  mutation DeleteTask($id: BigInteger!) {
    deleteTask(id: $id)
  }
`;
