import { gql } from "@apollo/client";

export const GET_ALL_TASKS = gql`
  query GetAllTasks {
    getAllTasks {
      id
      name
      completed
      due
      estimatedDuration
      timeZone
    }
  }
`;

export const GET_TASK = gql`
  query GetTask($id: BigInteger!) {
    getTask(id: $id) {
      id
      name
      completed
      due
      estimatedDuration
      timeZone
    }
  }
`;
